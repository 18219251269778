import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '1.5rem'
  },
  header: {
    marginBottom: '0.5rem'
  },
  content: {
    marginLeft: '10px',
    marginBottom: '1rem'
  },
}));

const Policy = ({ title, children }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.header} variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography className={classes.content} component="div">
        {children}
      </Typography>
    </>
  );
}

const PrivacyPolicy = () => {
  const classes = useStyles();

  return <>
    <Typography className={classes.title} variant="h6" gutterBottom>
      プライバシーポリシー
    </Typography>
    <Policy title={''}>
      &nbsp;&nbsp;当サロンでは、以下のとおり個人情報の取り扱いについて基本方針を定め、個人情報保護に厳重な注意を払ってまいります。
    </Policy>
    <Policy title={'個人情報の定義'}>
      &nbsp;&nbsp;本プライバシーポリシーにおける、「個人情報」とは、個人情報の保護に関する法律規定される「個人情報」及び当サロンのサービスの利用に係るパスワードを指します。
    </Policy>
    <Policy title={'個人情報の取得'}>
      &nbsp;&nbsp;当サロンは、利用目的の達成に必要な範囲で、個人情報を適正に取得し、不正の手段により取得することはありません。
    </Policy>
    <Policy title={'利用目的'}>
      &nbsp;&nbsp;当サロンは、取得した個人情報を以下の目的で利用します。<br />
      &nbsp;&nbsp;&nbsp;&nbsp;1. 当サロンの予約確認のため<br />
      &nbsp;&nbsp;&nbsp;&nbsp;2. 当サロンのサービスや商品の案内のため<br />
      &nbsp;&nbsp;&nbsp;&nbsp;3. 当サロンへのお問い合わせに回答するため<br />
      &nbsp;&nbsp;&nbsp;&nbsp;4. お忘れ物等のご連絡のため<br />
      &nbsp;&nbsp;&nbsp;&nbsp;5. お客様にご自身の登録情報の閲覧や変更、ご予約状況の閲覧を行っていただくため<br />
      &nbsp;&nbsp;&nbsp;&nbsp;6. 上記の利用目的に付随する目的
    </Policy>
    <Policy title={'第三者提供'}>
      &nbsp;&nbsp;当サロンは、法令に定められた場合を除き、ご本人様の事前の了解なく第三者に提供・提示されることはありません。
    </Policy>
    <Policy title={'開示、訂正、利用停止、削除'}>
      &nbsp;&nbsp;お客様から個人情報の開示、訂正、利用停止、削除を求められたときは、確認を取らせていただいた上で 適切に対処させていただきます。
    </Policy>
    <Policy title={'その他'}>
      &nbsp;&nbsp;今後も個人情報保護に万全を期すべく、以上の方針を今後実務の動向を踏まえ適宜改定してまいります。
    </Policy>
    <Typography className={classes.content} component="div">
      以上
    </Typography>
    <Typography className={classes.content} component="div">
      最終更新日　2021年5月5日
    </Typography>
  </>;
}

export default PrivacyPolicy;